


















import Vue from 'vue';
import AppListItem from '@/components/AppListItem.vue';
import { CoopInfoFragment, Maybe } from '@/types/schema';

export default Vue.extend({
  name: 'AppListCoop',
  components: {
    AppListItem,
  },
  inheritAttrs: false,
  props: {
    coop: {
      type: Object as () => Maybe<CoopInfoFragment>,
      default: null,
    },
  },
});
